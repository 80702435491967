 <template>
	<div class="page">

		<!-- 搜索条件表单 -->
		<el-form class="ser_form" size="small"  label-width="110px">
			<el-form-item class="el_form_item" label="货主电话">
				<el-input class="el_input" v-model="form.shipper_tel" clearable></el-input>
			</el-form-item>
			<el-form-item class="el_form_item" label="网点名称">
				<el-input class="el_input" v-model="form.outlets_name" clearable></el-input>
			</el-form-item>
			<el-form-item class="el_form_item" label="线路版块授权">
				<el-select class="el_input" v-model="form.bl_status" clearable>
					<el-option label="不限" value=""></el-option>
					<el-option label="不授权" value="1"></el-option>
					<el-option label="仅自有数据" value="2"></el-option>
					<el-option label="全部数据" value="3"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item class="el_form_item" label="货源版块授权">
				<el-select class="el_input" v-model="form.cargo_status" clearable>
					<el-option label="不限" value=""></el-option>
					<el-option label="不授权" value="1"></el-option>
					<el-option label="仅自有数据" value="2"></el-option>
					<el-option label="全部数据" value="3"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item class="el_form_item" label="运单版块授权">
				<el-select class="el_input" v-model="form.tords_status" clearable>
					<el-option label="不限" value=""></el-option>
					<el-option label="不授权" value="1"></el-option>
					<el-option label="仅自有数据" value="2"></el-option>
					<el-option label="全部数据" value="3"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item label-width="0">
				<el-button type="primary" @click="page_ser">查询</el-button>
				<el-button type="primary" @click="ser_para_clear">清空条件</el-button>
				<el-button type="primary" @click="auth_grant_creat_view_show">添加</el-button>
			</el-form-item>
		</el-form>

		<!-- 表格 -->
		<div class="tab_height">
			<el-table :data="list.data" :border="true" :stripe="true" size="small" height="100%" v-loading="list.loading">
				<el-table-column label="网点编号/授权时间" width="190">
					<template slot-scope="scope">	
						<div>编号: {{scope.row.outlets_num}}</div>
						<div>添加: {{scope.row.creat_time_text}}</div>
					</template>
				</el-table-column>
				<el-table-column label="网点名称">
					<template slot-scope="scope">
						{{scope.row.outlets_info.name}}
					</template>
				</el-table-column>
				<el-table-column label="网点地址">
					<template slot-scope="scope">
						<div class="tab_line_item">(省/市/区) {{scope.row.outlets_info.prov}} / {{scope.row.outlets_info.city}} / {{scope.row.outlets_info.county}}</div>
						<div class="tab_line_item">(详细地址) {{scope.row.outlets_info.addr}}</div>
					</template>
				</el-table-column>
				<el-table-column label="货主信息">
					<template slot-scope="scope">
						<div class="tab_line_item">(用户) {{scope.row.shipper_info.name}} / {{scope.row.shipper_info.tel}}</div>
						<div class="tab_line_item">(公司) {{scope.row.company_info.name}}</div>
					</template>
				</el-table-column>
				<el-table-column label="授权状态">
					<template slot-scope="scope">
						<div class="tab_line_item">(线路) {{scope.row.bl_status_text}}</div>
						<div class="tab_line_item">(货源) {{scope.row.cargo_status_text}}</div>
						<div class="tab_line_item">(运单) {{scope.row.tord_status_text}}</div>
					</template>
				</el-table-column>
				<el-table-column label="备注" prop="mark"></el-table-column>
				
				<!-- 操作行 -->
				<el-table-column label="操作" width="70">
					<template slot-scope="scope">
						<el-button @click="auth_grant_del(scope.row)" size="mini" type="text" style="color:red">撤销</el-button>
					</template>
				</el-table-column>
			</el-table>
		</div>

		<!-- 分页 -->
		<el-pagination 
			class="margin-top-xl" 
			@size-change="page_size_change"
			@current-change="page_current_change" 
			:current-page="page.p" 
			:page-sizes="[10, 20, 30, 40]"
			:page-size="page.num" 
			:total="list.total*1"
			layout="total, sizes,prev, pager, next, jumper" 
		>
		</el-pagination>

		<!-- 授权添加界面 -->
		<!-- <authGrantAdd 
			:is_show="auth_grant.is_show" 
			:outlets_info="auth_grant.data" 
			@closed="auth_grant_view_close" 
		></authGrantAdd> -->
		
		<shipperGrantAdd
			:is_show="auth_grant.is_show" 
			:outlets_info="auth_grant.data" 
			@closed="auth_grant_view_close" 
		></shipperGrantAdd>
		
	</div>
</template>

<script>
	// import authGrantAdd from './auth_grant_creat.vue'
	import shipperGrantAdd from './shipper_grant_creat.vue'
	
	export default {
		components:{
			// authGrantAdd
			shipperGrantAdd
		},
		data() {
			return {
				
				//授权界面显示状态
				auth_grant_is_show:0,
				
				//搜索条件
				form: {
					shipper_tel:'',//货主电话
					outlets_status:1,//网点状态(1:正常,2:停用)
					outlets_name:'',//网点名称
					bl_status:'',//线路版块授权状态(1:未授权,2:自有数据授权,3:全部数据授权)
					cargo_status:'',//货源版块授权状态(1:未授权,2:自有数据授权,3:全部数据授权)
					tord_status:'',//运单版块授权状态(1:未授权,2:自有数据授权,3:全部数据授权)
				},

				//表格数据
				list: {
					loading: false,//loading特效
					data: [],//列表数据
					total: 0,//列表总记录数
				},

				//页码信息
				page: {
					need_num:1,
					num: 10,
					p: 1,
				},

				//授权界面
				auth_grant:{
					is_show:0,
					data:{}
				}
			}
		},
		created() {

			//读取页面数据
			this.get_page_data()
		},
		methods: {
			
			//添加授权 页面显示
			auth_grant_creat_view_show(){
				this.auth_grant.is_show++;
				
			},

			//
			auth_grant_view_close(){

			},

			//撤销授权
			auth_grant_del(item){

				//询问
				this.$my.other.confirm({
					content:`撤销对'${item.outlets_info.name}'的授权, 请确认?`,
					confirm:()=>{
						
						//调接口
						this.$my.net.req({
							data:{
								mod:'truck_business_outlets',
								ctr:'grant_del_by_admin',
								id:item.id
							},
							callback:(data)=>{
								
								//报告结果
								this.$my.other.msg({
									type:'success',
									str:"操作成功"
								});

								//刷新
								this.get_page_data()
							}
						});
					}
				});
			},

			//清空查询条件
			ser_para_clear(){
				this.form={
					shipper_tel:'',//货主电话
					outlets_status:1,//网点状态(1:正常,2:停用)
					outlets_name:'',//网点名称
					bl_status:'',//线路版块授权状态(1:未授权,2:自有数据授权,3:全部数据授权)
					cargo_status:'',//货源版块授权状态(1:未授权,2:自有数据授权,3:全部数据授权)
					tord_status:'',//运单版块授权状态(1:未授权,2:自有数据授权,3:全部数据授权)
				}
				this.get_page_data()
			},

			//搜索
			page_ser(){

				//读取第一页
				this.page.p=1

				//读取
				this.get_page_data()
			},

			//页宽变化
			page_size_change(num){

				//置入页码
				this.page.num=num

				//读取
				this.get_page_data()
			},

			//页码变化
			page_current_change(p){
				
				//置入页码
				this.page.p=p

				//读取
				this.get_page_data()
			},

			//读取页面数据
			get_page_data(){

				//加载中...
				if(this.list.loading){
					return;
				}

				//置为加载中
				this.list.loading=true;

				//读取数据
				this.$my.net.req({
					data:{
						mod:'truck_business_outlets',
						ctr:'grant_list_by_admin',
						is_get_outlets_info:1,
						is_get_shipper_info:1,
						is_get_company_info:1,
						...this.form,
						...this.page
					},
					callback:(data)=>{


						//加载完毕
						this.list.loading=false

						//总数
						this.list.total=data.max

						//预处理
						for(let item of data.list){

							//时间
							item.creat_time_text=this.$my.other.totime(item.creat_time);

							//网点状态(1:正常,2:停用)
							// switch(item.outlets_status){
							// 	case '1':item.outlets_status_text='正常';break;
							// 	case '2':item.outlets_status_text='停用';break;
							// }
							
							//线路版块授权状态(1:未授权,2:自有数据授权,3:全部数据授权)
							switch(item.bl_status){
								case '1':item.bl_status_text='未授权';break;
								case '2':item.bl_status_text='仅自有数据';break;
								case '3':item.bl_status_text='全部数据';break;
							}

							//货源版块授权状态(1:未授权,2:自有数据授权,3:全部数据授权)
							switch(item.cargo_status){
								case '1':item.cargo_status_text='未授权';break;
								case '2':item.cargo_status_text='仅自有数据';break;
								case '3':item.cargo_status_text='全部数据';break;
							}

							//运单版块授权状态(1:未授权,2:自有数据授权,3:全部数据授权)
							switch(item.tord_status){
								case '1':item.tord_status_text='未授权';break;
								case '2':item.tord_status_text='仅自有数据';break;
								case '3':item.tord_status_text='全部数据';break;
							}
						}

						//渲染
						this.list.data=data.list
					}
				})
			},
		}
	}
</script>

<style lang="scss" scoped>

	//其他
	.page{
		background-color: #fff;
		padding: 20px;
		border-radius: 10px;
		height: calc(100% - 40px);
		overflow: auto;
	}
	.ser_form{
		display: flex;
		align-items: flex-end;
		flex-wrap: wrap;
	}
	.tab_height{
		height:calc(100% - 90px);
	}
	.el_form_item{
		width: 16.6672%;
	}
	.el_input{
		width:94%
	}
	.btn_left{
		margin: 0;
	}
</style>